/* global FB, google */

Apt.fn.make('auth', {
	/**
	 * Bind auth events
	 */
	bind: function() {
		var scope = this;

		LS.util.idle([
			scope.bindLogin,
			scope.bindLogout,
			scope.bindRegister
		], scope);
	},

	/**
	 * Initialize login
	 */
	login: function() {
		var scope = this;

		LS.api.bind('$loginForm', 'auth/login', {
			namespace: 'auth',
			success: function(data) {
				scope.processLogin(data, 'email');
			}
		});

		scope.initServices();
	},

	/**
	 * Initialize registration
	 */
	register: function() {
		var scope = this;

		LS.api.bind('$registerForm', 'auth/register', {
			namespace: 'auth',
			success: function(data) {
				scope.processRegister(data, 'email');
			},
			validate: function(data) {
				var name = data.first_name + data.last_name;

				if (name && ! LS.validate.isMixedCase(name)) {
					LS.util.warn('Name must be "Title Case"');

					return false;
				}

				return true;
			}
		});

		scope.initServices();
	},

	/**
	 * Initialize forgot password
	 */
	forgot: function() {
		LS.api.bind('$forgotForm', 'auth/forgot', {
			namespace: 'auth',
			success: function() {
				LS.modal.close();
			}
		});
	},

	/**
	 * Initialize password reset
	 */
	reset: function() {
		LS.api.bind('$resetForm', 'auth/reset', {
			namespace: 'auth',
			data: function(data) {
				data.token = $.context('token');

				return data;
			},
			success: function(data) {
				LS.modal.close();
				LS.user.set(data);

				$.history.go('/account');
			}
		});
	},

	/**
	 * Initialize activation page
	 */
	activate: function() {
		LS.api.post('auth/activate', {
			data: {
				token: $.context('token')
			},
			success: function(data) {
				LS.user.set(data);

				setTimeout(function() {
					$.history.go('/account/welcome');
				}, 1000);
			},
			error: function() {
				$.history.go('/auth/forgot');
			}
		});
	},

	/**
	 * Prompt for login
	 *
	 * @param {Function} [fn]
	 * @param {Object} [options]
	 */
	prompt: function(fn, options) {
		var scope = this;

		if ($.routes.segments(1) === 'login') {
			return;
		}

		if (LS.user.active()) {
			$.exec(fn);

			return;
		}

		scope.fn = fn;

		LS.modal.open($.extend({
			url: '/auth/login',
			success: function() {
				scope.login();
				scope.bindForgot(true);
				scope.bindRegister(true);
			},
			onHide: function() {
				$.events.reset('auth');
			}
		}, options));
	},

	/**
	 * Bind login
	 *
	 * @param {Boolean} [modal]
	 */
	bindLogin: function(modal) {
		var scope = this;

		$.events.on('$login', 'click', function() {
			scope.prompt();
		}, {
			cancel: true,
			delegate: modal ?
				'$modal' : '$header',
			namespace: modal ?
				'auth' : null
		});
	},

	/**
	 * Bind registration
	 *
	 * @param {Boolean} [modal]
	 */
	bindRegister: function(modal) {
		var scope = this;

		$.events.on('$register', 'click', function(e, el) {
			if ($.routes.segments(1) === 'register') {
				return;
			}

			LS.modal.open({
				url: el.href,
				success: function() {
					scope.register();
					scope.bindLogin(true);
				},
				onHide: function() {
					$.events.reset('auth');
				}
			});
		}, {
			cancel: true,
			delegate: modal ?
				'$modal' : '$header',
			namespace: modal ?
				'auth' : null
		});
	},

	/**
	 * Bind forgot password
	 *
	 * @param {Boolean} [modal]
	 */
	bindForgot: function(modal) {
		var scope = this;

		$.events.on('$forgot', 'click', function(e, el) {
			LS.modal.open({
				url: el.href,
				success: function() {
					scope.forgot();
					scope.bindLogin(true);
				},
				onHide: function() {
					$.events.reset('auth');
				}
			});
		}, {
			cancel: true,
			delegate: modal ?
				'$modal' : '$header',
			namespace: modal ?
				'auth' : null
		});
	},

	/**
	 * Bind logout
	 */
	bindLogout: function() {
		$.events.on('$logout', 'click', function() {
			LS.user.drop();

			LS.util.flash('Goodbye — you are logged out', 'success');

			if ($.routes.segments(0) === 'account') {
				$._loc.href = '/';
			}
		}, {
			cancel: true,
			delegate: '$header'
		});
	},

	/**
	 * Process login
	 *
	 * @param {Object} data
	 * @param {String} method
	 */
	processLogin: function(data, method) {
		var uri = $.routes.uri(),
			redirect = uri.query.return;

		LS.user.set(data);

		LS.modal.close();

		$.exec(this.fn);

		LS.analytics.push({
			event: 'login',
			target: method
		});

		if (redirect) {
			$.history.go(redirect);
		} else if (uri.segments[0] === 'auth') {
			$.history.go('/account');
		}
	},

	/**
	 * Process registration
	 *
	 * @param {Object} data
	 * @param {String} method
	 */
	processRegister: function(data, method) {
		if (data.token) {
			LS.user.set(data);
		}

		LS.modal.close();

		$.exec(this.fn);

		LS.analytics.push({
			event: 'register',
			target: method
		});
	},

	/**
	 * Initialize auth services
	 */
	initServices: function() {
		var scope = this;

		$$('authService').on('click', function(e, el) {
			scope['init' + LS.util.capitalize(el.dataset.service)]();
		}, {
			namespace: 'auth'
		});
	},

	/**
	 * Initialize Facebook
	 */
	initFacebook: function() {
		var scope = this;

		$.assets.load({
			js: 'https://connect.facebook.net/en_US/sdk.js',
			success: function() {
				FB.init({
					appId: 217925998728540,
					version: 'v2.9'
				});

				FB.login(function(response) {
					if (response.authResponse) {
						scope.connectService('facebook', response.authResponse.accessToken);
					} else {
						scope.failService();
					}
				}, {
					scope: 'email'
				});
			}
		});
	},

	/**
	 * Initialize Google
	 */
	initGoogle: function() {
		var scope = this;

		$.assets.load({
			js: 'https://accounts.google.com/gsi/client',
			success: function() {
				var client = google.accounts.oauth2.initTokenClient({
					client_id: '495794482008-t3qu7gbkjkjf5dnqdgbflmim6hm0fmtp.apps.googleusercontent.com',
					scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
					callback: function(response) {
						if (response && response.access_token) {
							scope.connectService('google', response.access_token);
						} else {
							scope.failService();
						}
					}
				});

				client.requestAccessToken();
			}
		});
	},

	/**
	 * Connect service
	 *
	 * @param {String} service
	 * @param {String} token
	 */
	connectService: function(service, token) {
		var scope = this;

		LS.api.post('auth/service', {
			data: {
				service: service,
				token: token
			},
			success: function(data) {
				scope[data.new ? 'processRegister' : 'processLogin'](data, service);
			},
			error: function() {
				scope.failService();
			}
		});
	},

	/**
	 * Display service error
	 */
	failService: function() {
		LS.util.warn('Access failed using this method');
	}
});