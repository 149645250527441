Apt.fn.make('lazy', {
	/**
	 * Initialize module
	 *
	 * @param {$} target
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	init: function(target, options) {
		var scope = this,
			priv = scope.$private;

		priv.target = $(target);
		priv.uid = LS.util.uid();

		priv.conf = $.extend({
			margin: 360,
			once: true
		}, options);

		priv.bind();

		return scope;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var priv = this.$private,
			uid = priv.uid;

		$.events.reset(uid);
		$.scroll.reset(uid);

		priv.target = [];
	}
}, {
	/**
	 * Bind lazy load events
	 */
	bind: function() {
		var scope = this,
			conf = scope.conf;

		scope.target = scope.target.filter('img')
			.add(
				scope.target.filter('picture')
					.find('img')
			)
			.attr('data-ignore', true);

		var fn = function(e) {
			var el = e.target,
				picture = el.tagName === 'PICTURE',
				img = picture ?
					el.querySelector('img') : el;

			img.decoding = 'async';

			img.addEventListener('load', function() {
				requestAnimationFrame(function() {
					img.classList.add('-is-loaded');
				});
			});

			var source = picture ?
				el.querySelector('source') : null;

			if (source) {
				source.srcset = source.dataset.srcset;
			}

			img.src = img.dataset.src;
		};

		requestAnimationFrame(function() {
			LS.util.scrolled(scope.target, scope.uid, fn, conf);

			$.scroll.trigger();
		});
	}
});