Apt.fn.make('truncate', {
	/**
	 * Initialize module
	 *
	 * @param {$} target
	 * @returns {Object}
	 */
	init: function(target) {
		var scope = this,
			priv = scope.$private,
			$target;

		if ($._win.crawler) {
			return scope;
		}

		$target = $(target);

		priv.conf = {
			content: $target[0].innerHTML,
			label: $target[0].dataset.label || 'Show more',
			limit: $target[0].dataset.limit || (
				$.screen.size() < 3 ?
					360 : 500
			),
			namespace: LS.util.uid(),
			$target: $target
		};

		priv.setup();

		return scope;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var conf = this.$private.conf;

		if (conf) {
			$.events.reset(conf.namespace);
		}
	}
}, {
	/**
	 * Truncate initial text
	 */
	setup: function() {
		var conf = this.conf,
			$wrapper;

		if (conf.$target.height() <= (conf.limit * 1.2) + 50) {
			return;
		}

		$wrapper = $('<div class="truncated"/>');

		$wrapper.append(conf.$target.children());

		conf.$target.append($wrapper);

		var $more = $('<button class="-bordered truncated__more">' + conf.label + '</button>');

		$more.on('mousedown', function() {
			conf.$target.html($wrapper[0].innerHTML);
		}, {
			namespace: conf.namespace
		});

		conf.$target.append($more);
	}
});