(function() {
	var load = LS.util.load,
		filters = {
			guard: function() {
				if (LS.user.active()) {
					return true;
				}

				$._loc.href = '/auth/login?return=' + $._loc.pathname;

				return false;
			},
			index: function(segment) {
				return ! segment || segment === 'filter' || /^p\d+$/.test(segment);
			},
			directory: function(segment) {
				return LS.filters.isDirectory(segment);
			},
			property: function(segment) {
				return LS.filters.isProperty(segment);
			},
			segment: function(segment) {
				return ! filters.entry(segment);
			},
			entry: function(segment) {
				return /^\d{6,}$/.test(segment);
			}
		};

	$.routes.config('before', function(conf) {
		if ($$('error').length) {
			conf.path = 'error';
		}
	});

	$.routes.addFilter(filters);

	$.routes.map({
		'$any:fire:once': function() {
			LS.common.init();
		},

		'$root:preload:once': function() {
			load('home');
		},

		'$property': {
			':preload': 'properties:preload',
			'$any': {
				'$segment': {
					'$any': {
						':load': 'properties',
						':unload': 'properties:unload'
					}
				}
			},
			'$slug': {
				'$entry:preload:once': function() {
					load('property');
				}
			}
		},

		'$directory': {
			'$any': {
				'$segment': {
					'$index:preload:once': function() {
						load('directory');
					}
				}
			},
			'$slug': {
				'$entry:preload:once': function() {
					load('profile');
				}
			}
		},

		'collections': {
			'$any:preload:once': function() {
				load('collection');
			}
		},

		'blog': {
			'$any:preload:once': function() {
				load('blog');
			}
		},

		'support': {
			'$any:preload:once': function() {
				load('support');
			}
		},

		'advertise:preload:once': function() {
			load('advertise');
		},

		'careers': {
			'$any:preload:once': function() {
				load('careers', null, false);
			}
		},

		'contact:preload:once': function() {
			load('contact');
		},

		'feed': {
			'$any:preload:once': function() {
				load('feed');
			}
		},

		'about': {
			'$any:preload:once': function() {
				load('about', null, false);
			}
		},

		'news': {
			'$/^(?!(p\\d+)).*$/': {
				':load': 'article',
				':unload': 'article:unload'
			}
		},

		'corp': {
			'$any': {
				':load': 'article',
				':unload': 'article:unload'
			}
		},

		'price:preload:once': function() {
			load('price');
		},

		'loan-calculator:preload:once': function() {
			load('loan');
		},

		'developers': {
			'$any:preload:once': function() {
				load('developers');
			}
		},

		'sitemap': {
			'$any:preload:once': function() {
				load('sitemap', null, false);
			}
		},

		'account': {
			'$any:preload:once': function() {
				load('account');
			}
		},

		'auth': {
			'login': 'auth:login',
			'logout': 'auth:logout',
			'register': 'auth:register',
			'forgot': 'auth:forgot',
			'reset': {
				'$token': 'auth:reset'
			},
			'activate': {
				'$token': 'auth:activate'
			}
		},

		'$any:once:fire': function() {
			LS.common.setup();
		}
	});
})();