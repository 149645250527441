LS.support = {
	/**
	 * Initialize browser feature detection
	 */
	check: function() {
		$.supported('touch', {
			callback: function() {
				$._html.classList.remove('-c');
				$._html.classList.add('-t');
			}
		});
	}
};