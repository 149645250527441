Apt.fn.extend('filters', {}, {
	/**
	 * Bind filter check events
	 */
	bindCheck: function() {
		var scope = this,
			$checks = $$('filterCheck');

		$.events.on('$filterCheck', 'change', function(e, el) {
			if (
				el.name === 'tag' ||
				el.name === 'type'
			) {
				$.app.filters.$set(el.name, $checks.filter('[name="' + el.name + '"][checked]').map(function(item) {
					return item.value;
				}));
			}

			scope.mergeData();
		}, {
			delegate: '$filters',
			namespace: 'filters'
		});
	},

	/**
	 * Bind checkbox navigation events
	 *
	 * @param {HTMLElement} el
	 */
	bindCheckNav: function(el) {
		var scope = this,
			$body = $($._body),
			cancel = true;

		$body
			.off('.filterChecks')
			.on({
				keydown: function(e) {
					var key = e.keyCode;
					cancel = true;

					if (key === 40 || key === 38) {
						// Arrow up or down
						cancel = false;
						e.preventDefault();
					} else if (key === 13 || key === 32) {
						// Enter or space
						if (scope.$activeCheck) {
							scope.selectCheck();
							e.preventDefault();
						}
					} else {
						scope.$activeCheck = false;
					}
				},
				keyup: function(e) {
					if (! cancel) {
						scope.processCheck(el, e.keyCode);
					}
				}
			}, {
				namespace: 'filterChecks'
			});
	},

	/**
	 * Process the keyup event for evaluation
	 *
	 * @param {HTMLElement} el
	 * @param {Number} key
	 */
	processCheck: function(el, key) {
		var scope = this,
			activeClass = '-is-active';

		if (scope.$activeCheck) {
			scope.$activeCheck.removeClass(activeClass);

			scope.$activeCheck = key === 40 ?
				scope.$activeCheck.next() :
				scope.$activeCheck.prev();
		} else {
			scope.$entries = $('input[type="checkbox"]', $(el).next('$filterContent'));

			if (scope.$entries.length) {
				scope.$activeCheck = key === 40 ?
					scope.$entries.first() :
					scope.$entries.last();
			} else {
				scope.$activeCheck = false;
			}
		}

		if (scope.$activeCheck.length) {
			scope.$activeCheck.addClass(activeClass);
		} else {
			scope.$activeCheck = false;
		}
	},

	/**
	 * Make check option selection
	 */
	selectCheck: function() {
		var $check = $$('filterCheck', this.$activeCheck);

		$check.prop('checked', ! $check.prop('checked'))
			.trigger('change');

		this.$activeCheck[0].classList.add('-is-active');
	}
});