Apt.fn.make('message', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private,
			conf = $.extend({
				el: '$message',
				path: 'message',
				context: {}
			}, options),
			$el = $(conf.el);

		priv.conf = conf;
		priv.uid = LS.util.uid();

		if (! options.gate && conf.target) {
			var previous = LS.store.get(conf.target + '_messages', {})[conf.id];

			if (previous) {
				options.gate = {
					date: Math.round((Date.now() - previous.sent) / 60000),
					message: 'You sent a message {{ date|dayFormat }} ago. Consider calling if you don\'t hear back.',
					cta: 'Send follow up'
				}
			}
		}

		$el.html($.view.render('message.form', {
			placeholder: conf.placeholder,
			gate: options.gate,
			required: conf.required || options.gate
		}));

		conf.$form = $$('messageForm', $el);

		priv.bind($el, options.gate);

		priv.populate();

		$$('messageContent', $el).resize(priv.uid);

		return scope;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		LS.util.reset(this.$private.uid, null, null, true);
	}
}, {
	/**
	 * Bind message events
	 *
	 * @param {$} $el
	 * @param {Boolean} [gate=false]
	 */
	bind: function($el, gate) {
		var scope = this,
			conf = scope.conf,
			path = conf.path,
			confirm;

		if (gate) {
			var $gate = $$('messageGate');

			$gate.on('mousedown', function() {
				$gate.hide();
			}, {
				namespace: scope.uid
			});
		}

		if (conf.target) {
			path = conf.target + 's/' + conf.id + '/message';
		}

		LS.api.bind(conf.$form, path, {
			namespace: scope.uid,
			flash: false,
			data: function(data) {
				data.url = $._loc.href;

				if (confirm) {
					data.confirm = true;
				}

				return data;
			},
			success: function(data, xhr, req) {
				$el[0].innerHTML = conf.sent ?
					conf.sent(data) : $.view.render('message.sent', data);

				if (conf.target) {
					LS.analytics.track({
						action: 'submit',
						target: conf.target,
						id: conf.id,
						category: 'message',
						attributes: {
							id: Number(data.id)
						}
					}, conf.context);
				}

				if ($.screen.size() < 4) {
					LS.util.setScroll($el);
				}

				LS.store.set('user_contact', {
					name: req.data.name,
					email: req.data.email,
					phone: {
						formatted: req.data.phone
					}
				});

				if (conf.target) {
					var key = conf.target + '_messages',
						messages = LS.store.get(key, {});

					messages[conf.id] = {
						sent: Date.now()
					};

					LS.store.set(key, messages);
				}
			},
			error: function(xhr, opt, message) {
				if (xhr.status === 400) {
					var force = JSON.parse(xhr.responseText).force;

					LS.modal.open({
						model: {
							title: 'Review submission',
							content: $.view.render('message.prompt', {
								force: force,
								message: message
							}),
							footer: force ?
								null : '<p class="g-s-0">Sent messages are subject to additional review and possible rejection.</p>'
						},
						onHide: function() {
							$.events.reset('messagePrompt');
						}
					});

					$.events.on({
						'$messageSend': {
							click: function() {
								confirm = true;

								conf.$form.trigger('submit');

								LS.modal.close();
							}
						},
						'$messageEdit': {
							click: function() {
								LS.modal.close();

								$$('messageContent', $el).focus(true);
							}
						}
					}, {
						namespace: 'messagePrompt'
					});

					return;
				}

				LS.util.warn(message);

				if (xhr.status && xhr.status !== 422) {
					LS.error.report('MessageError', xhr.responseText, {
						owner_id: conf.id,
						status: xhr.status
					}, {
						data: conf.data
					});
				}
			}
		});
	},

	/**
	 * Populate form with user data
	 */
	populate: function() {
		var scope = this;

		$.observe('user', function(data) {
			if (! data.account) {
				scope.fill(LS.store.get('user_contact'));

				return;
			}

			scope.fill(data.account);

			$.unobserve('*', {
				namespace: scope.uid
			});
		}, {
			init: true,
			namespace: scope.uid
		});
	},

	/**
	 * Fill form with user data
	 *
	 * @param {Object} [data]
	 */
	fill: function(data) {
		if (! data) {
			return;
		}

		var el = this.conf.$form[0],
			phone = $$('messagePhone', el)[0];

		$$('messageName', el)[0].value = data.name || (
			data.first_name + ' ' + data.last_name
		);

		$$('messageEmail', el)[0].value = data.email;

		if (! phone.value && data.phone) {
			phone.value = data.phone.formatted;
		}
	}
});