LS.common = {
	/**
	 * Initialize module
	 */
	init: function() {
		LS.support.check();

		LS.store.init();
		LS.header.init();

		this.initDark();

		LS.filters.listen();

		LS.user.init();
		LS.analytics.init();

		$.view.addGlobal({
			appUrl: $.get('appUrl'),
			cdnUrl: $.get('cdnUrl')
		});
	},

	/**
	 * Deferred module initialization
	 */
	setup: function() {
		var scope = this;

		if ($._win.crawler) {
			return;
		}

		LS.header.locate();

		Apt.auth.bind();

		LS.util.idle([
			scope.initHistory,
			scope.initFooter,
			scope.initCredit,
			scope.initFeedback
		], scope);
	},

	/**
	 * Initialize History navigation
	 */
	initHistory: function() {
		var scope = this,
			partials = 'title, main, .js-context';

		$.history.init({
			partials: partials,
			processErrors: true,
			scrollTop: function() {
				var hash = $._loc.hash;

				if (hash) {
					return LS.util.getScroll('#' + hash);
				}

				return 0;
			},
			begin: function(conf) {
				if (! conf.run) {
					return;
				}

				$.fetch.abort('api');

				if (LS.confirm && ! LS.confirm(conf)) {
					return false;
				}

				if (conf.push || conf.pop) {
					LS.modal.close();
					scope.transition();
				}

				LS.header.hide();
			},
			replace: function(html, conf) {
				if (conf.partials !== partials || ! Apt.panel.opened()) {
					return;
				}

				var $el = $(html);

				if (! $el.find('$detail').length) {
					Apt.panel.hide({
						history: conf
					});

					return;
				}

				if ($el.find('.js-replace').length) {
					conf.partials = 'title, .js-replace, .js-context';
					conf.scrollTarget = '$panelInner';
				}
			},
			end: function(obj) {
				var conf = obj.conf;

				if (conf.push || conf.pop) {
					if (conf.run) {
						scope.transition(true);
					}

					LS.analytics.push({
						event: 'history'
					});
				}
			},
			request: {
				error: function(xhr, conf) {
					var status = xhr.status;

					if (status) {
						if (status === 403) {
							$._win.location = conf.url;
						}

						LS.error.report('HistoryError', xhr.responseText, {
							status: status,
							url: conf.url
						});
					}
				}
			}
		});
	},

	/**
	 * Set page transition
	 *
	 * @param {Boolean} [end=false]
	 */
	transition: function(end) {
		var className = '-is-transitioning';

		if (end) {
			requestAnimationFrame(function() {
				$._body.classList.remove(className);
			});

			return;
		}

		$._body.classList.add(className);
	},

	/**
	 * Initialize footer
	 */
	initFooter: function() {
		var $form = $('$newsletter');

		LS.api.bind($form, 'mailer/subscriber', {
			method: 'put',
			data: function(data) {
				data.list_id = 1; // eslint-disable-line
				data.meta = {
					source: $.routes.segments(0)
				};

				return data;
			},
			success: function() {
				LS.analytics.push({
					event: 'subscribe'
				});

				$form[0].reset();
			}
		});
	},

	/**
	 * Initialize feedback
	 */
	initFeedback: function() {
		$$('feedback').on('click', function() {
			LS.util.load('feedback', function() {
				Apt.feedback.init();
			}, true, false);
		}, {
			cancel: true
		});
	},

	/**
	 * Initialize credit
	 */
	initCredit: function() {
		$$('mapCredit').on('click', function () {
			$.fetch.request({
				url: '/assets/' + $.get('versions.core') + '/js/views/credit.html',
				success: function(template) {
					LS.modal.open({
						model: {
							title: 'Map attribution',
							content: $.view.render(template)
						}
					});
				}
			});
		}, {
			delegate: $._body
		});
	},

	/**
	 * Initialize dark mode events
	 */
	initDark: function() {
		var scope = this,
			dark;

		scope.setDark(scope.isDark(), true);

		$$('dark').on('mousedown', function() {
			dark = scope.isDark();

			scope.setDark(! dark, true);

			LS.analytics.interact('global', 'scheme', dark ?
				'light' : 'dark'
			);
		});

		try {
			$._win.matchMedia('(prefers-color-scheme: dark)')
				.addEventListener('change', function(e) {
					scope.setDark(e.matches);
				});
		} catch (e) {
			//
		}
	},

	/**
	 * Get theme state
	 *
	 * @returns {Boolean}
	 */
	isDark: function() {
		var value = LS.store.get('dark');

		return value === true || (
			value !== false && (
				$._win.matchMedia &&
				$._win.matchMedia('(prefers-color-scheme: dark)').matches
			)
		);
	},

	/**
	 * Toggle dark mode
	 *
	 * @param {Boolean} [enable=false]
	 * @param {Boolean} [set=false]
	 */
	setDark: function(enable, set) {
		var style = $$('darkStyle')[0],
			schemeClass = '-is-scheming';

		if (! style) {
			return;
		}

		if (set) {
			$._html.classList.add(schemeClass);

			LS.store.set('dark', enable);
		}

		if (enable) {
			$._html.classList.add('-d');

			style.disabled = false;
			style.media = '';
		} else {
			$._html.classList.remove('-d');

			style.disabled = true;
		}

		if (set) {
			$($._win).trigger('theme');

			setTimeout(function() {
				$._html.classList.remove(schemeClass);
			}, 300);
		}
	},

	/**
	 * Toggle basic header
	 *
	 * @param {Boolean} [enable=false]
	 * @param enable
	 */
	setBasic: function(enable) {
		var basicClass = '-is-basic';

		if (enable) {
			$._body.classList.add(basicClass);
		} else {
			$._body.classList.remove(basicClass);
		}
	}
};