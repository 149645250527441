(function(L, A) {
	'use strict';

	/**
	 * Cast selection as Apt object
	 *
	 * @private
	 * @param {($|HTMLElement|String)} sel
	 * @param {($|HTMLElement|String)} [context=document]
	 */
	var Get = function(sel, context) {
			if (sel) {
				var els = Array.isArray(sel) ?
						sel : L.$toArray(L.$(sel, context)),
					i = 0;

				for (; i < els.length; i++) {
					var el = els[i];

					if (el) {
						A.call(this, el);
					}
				}

				this.sel = sel;
			}
		},
		$;

	/**
	 * Create chainable Apt object from selection
	 *
	 * @param {($|HTMLElement|String)} sel
	 * @param {($|HTMLElement|String)} [context=document]
	 * @returns {Object}
	 */
	$ = L._win[L._$] = function(sel, context) {
		return new Get(sel, context);
	};

	/**
	 * Create chainable Apt object from reference
	 *
	 * @param {String} ref
	 * @param {($|HTMLElement|String)} [context=document]
	 * @returns {Object}
	 */
	L._win[L._$$] = function(ref, context) {
		if (typeof ref === 'string') {
			ref = '$' + ref.replace(/, */g, ',$');
		}

		return new Get(ref, context);
	};

	// Extend core with chain registration method
	L.fn.extend({
		/**
		 * Register a new chainable method
		 *
		 * @param {(Object|String)} a - method name or object
		 * @param {Function} b
		 */
		$chain: function(a, b) {
			if (typeof a === 'string') {
				$.fn[a] = b;
			} else {
				for (var key in a) {
					$.fn[key] = a[key];
				}
			}
		}
	});

	// Shortcut core methods from alias
	for (var key in L) {
		var val = L[key];

		$[key.replace(/^\$/, '')] = typeof val === 'function' ?
			val.bind(L) :
			val;
	}

	// Bind core chainable methods
	$.fn = Get.prototype = {
		_$: true,
		length: 0,

		/**
		 * Execute function for each object element
		 *
		 * @param {Function} fn
		 * @param {Object} [options]
		 * @param {Array} [options.args]
		 * @param {Object} [options.context=document]
		 * @param {Boolean} [options.reverse=false]
		 * @param {Array} [options.scope]
		 * @returns {Object}
		 */
		each: function(fn, options) {
			L.$each(this, fn, options);

			return this;
		},

		/**
		 * Translate object elements to a new array
		 *
		 * @param {Function} fn
		 * @param {Object} [options]
		 * @param {Array} [options.args]
		 * @param {Object} [options.scope]
		 * @returns {Array}
		 */
		map: function(fn, options) {
			return L.$map(this, fn, options);
		},

		/**
		 * Reverse the order of object elements
		 *
		 * @returns {$}
		 */
		reverse: function() {
			var copy = L.$copy(this),
				x = this.length,
				rem = x,
				i = 0;

			for (; i < x; i++) {
				rem--;
				this[i] = copy[rem];
			}

			return this;
		},

		/**
		 * Convert selection to standard array
		 *
		 * @returns {Object}
		 */
		toArray: function() {
			return [].slice.call(this);
		}
	};

	// Map shorthand registration method
	$.fn.extend = L.$chain;
})(Apt, [].push);