Apt.fn.make('properties.panel', {
	/**
	 * Initialize property panel module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = Apt.properties.$private,
			openClass = '-is-expanded',
			map,
			enlarge,
			pos;

		if ($.screen.size() > 1) {
			$.events.on('.preview a', 'mousedown', function(e, el) {
				var cover = $('img', el)[0];

				if (cover && cover.src.indexOf('/small/') > -1) {
					$.assets.load({
						img: cover.src.replace('/small/', '/large/')
					});
				}
			}, {
				delegate: '$propertiesMap, $propertiesRight',
				namespace: 'properties'
			});
		}

		Apt.panel.init({
			delegate: '$propertiesMap, $propertiesRight',
			target: '.preview a',
			click: function() {
				if (options.sync) {
					options.sync.pause();
				}

				if (! map) {
					map = options.map();
				}

				if (map) {
					map.pausePosition();

					priv.clearMap(map);
				}
			},
			loaded: function() {
				$._body.classList.add(openClass);

				if (map) {
					map.hideTooltip();
				}

				if (! map || $.screen.size() < 4) {
					return;
				}

				enlarge = map.enlarged();
				pos = map.getPosition();

				map.minimize();
				map.resize();
			},
			hidden: function(conf) {
				conf = conf || {};

				if (! map) {
					map = options.map();
				}

				$._body.classList.remove(openClass);

				if (Apt.property) {
					Apt.property.unload();
				}

				if (conf.filter) {
					return;
				}

				if (! conf.close) {
					var history = $.history.get(true) || {};

					if (conf.init && history.push) {
						priv.refresh();
					} else if (
						LS.filters.parse(history.segments).section !== 'property'
					) {
						Apt.properties.unload();

						return;
					}
				}

				if (! map) {
					return;
				}

				if ($.screen.size() < 4) {
					if (priv.activeView === 'map') {
						LS.util.crop();
					}

					map.resumePosition();

					return;
				}

				if (enlarge) {
					map.maximize();
				}

				map.resize(function() {
					map.highlight();

					map.resumePosition();

					if (pos) {
						map.center(pos.center, {
							animate: false,
							zoom: pos.zoom
						});
					}

					if (options.sync) {
						options.sync.resume();
					}
				});
			}
		});

		return scope;
	}
});