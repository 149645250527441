Apt.fn.make('panel', {
	/**
	 * Setup module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		if ($._win.crawler) {
			return;
		}

		var scope = this,
			conf = $.extend({
				target: 'a',
				title: $._doc.title
			}, options);

		scope.conf = conf;

		scope.$private.bind();

		$$('panelClose').on('mousedown', function() {
			scope.close(conf);
		}, {
			namespace: 'panel'
		});

		$($._win).on('keydown', function(e) {
			if (
				e.keyCode === 27 &&
				Apt.panel.open &&
				! Apt.panel.locked &&
				! LS.modal.opened()
			) {
				scope.close(conf);
			}
		}, {
			namespace: 'panel'
		});

		return scope;
	},

	/**
	 * Hide currently open panel
	 *
	 * @param {Object} [options]
	 */
	hide: function(options) {
		if (! Apt.panel.open) {
			return;
		}

		var conf = this.conf;

		Apt.panel.open = false;

		$.set('context', conf.context, {
			trigger: false
		});

		$$('panel').removeClass('-is-loaded');

		if (conf.$mask) {
			conf.$mask.off('mousedown');

			conf.$mask.remove();

			conf.$mask = null;
		}

		LS.util.uncrop();

		$.scroll.trigger();

		$.exec(conf.hidden, {
			args: options
		});

		$('.js-replace').empty();

		$($._win).trigger('pop');
	},

	/**
	 * Manually trigger panel event
	 *
	 * @param {HTMLElement} el
	 * @param {String} [url]
	 */
	trigger: function(el, url) {
		var scope = this,
			conf = scope.conf,
			$panel = $$('panel'),
			$inner = $$('panelInner', $panel),
			loadingClass = [
				'-is-disabled',
				'-is-loading'
			];

		$$($._body).trigger('mousedown');

		$.exec(conf.click, {
			args: el
		});

		if (! Apt.panel.open) {
			conf.source = $.routes.uri().raw;
			conf.context = $.context();
		}

		Apt.panel.open = Apt.panel.locked = true;

		$inner.addClass(loadingClass);

		$.history.go({
			path: url || el.href,
			partials: 'title, .js-replace, .js-context',
			scrollTarget: '$panelInner',
			processErrors: false,
			request: {
				success: function() {
					$panel[0].classList.add('-is-loaded');

					$inner.scrollTop(0);

					scope.$private.mask();

					LS.util.crop();

					$.exec(conf.loaded, {
						args: el
					});

					Apt.panel.locked = false;
				},
				error: function(xhr) {
					var message = 'Unable to load — please try again';

					Apt.panel.open = false;

					try {
						message = JSON.parse(xhr.responseText).message;
					} catch (e) {
						//
					}

					LS.util.warn(message);
				},
				complete: function() {
					$inner.removeClass(loadingClass);

					LS.common.transition(true);
				}
			}
		});
	},

	/**
	 * Close currently open panel
	 */
	close: function() {
		var scope = this,
			conf = scope.conf;

		scope.hide({
			close: true
		});

		$.history.go({
			action: false,
			bypass: true,
			force: true,
			path: conf.source,
			run: false,
			scrollTop: false,
			title: conf.title
		});
	},

	/**
	 * Check panel open state
	 *
	 * @returns {Boolean}
	 */
	opened: function() {
		return Apt.panel.open === true;
	},

	/**
	 * Unload module
	 */
	unload: function() {
		$.events.reset('panel');
	}
}, {
	/**
	 * Bind target links to load into panel
	 */
	bind: function() {
		var scope = this,
			conf = scope.$public.conf;

		$.events.on(conf.target, 'click', function(e, el) {
			if (e.metaKey) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			scope.$public.trigger(el);
		}, {
			delegate: conf.delegate,
			namespace: 'panel'
		});
	},

	/**
	 * Create panel mask
	 */
	mask: function() {
		var pub = this.$public,
			conf = pub.conf;

		if (conf.$mask) {
			return;
		}

		conf.$mask = $('<div class="panel__mask"/>');

		conf.$mask.on('mousedown', function() {
			pub.close();
		}, {
			namespace: 'panel'
		});

		$($._body).append(conf.$mask);
	}
});